import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/seo";
import * as S from "../components/SuccessAndNotFound/styled";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <S.SectionWrapper>
      <S.SectionContent>
        <h1>404</h1>
        <h2>Ops... não tem nada aqui.</h2>
        <S.SectionLink to="/">Voltar para a página inicial</S.SectionLink>
      </S.SectionContent>
    </S.SectionWrapper>
  </Layout>
);

export default NotFoundPage;
